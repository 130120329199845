import type { CustomOptionsProps } from '../../types';

export const customOptions: CustomOptionsProps = {
  languageCode: 'en',
  regionISO: 'US',
  cookieManagementEnabled: true,
  googleAdsEnabled: false,
  headerLiftEnabled: true,
  googleSearchConsoleId: '',
  googleTagManagerCode: '',
  googleAnalyticsId: '',
  facebookTrackingId: '',
  twitterHandle: '@gamedistribution',
  twitterSiteHandle: '@azerion',
  appIconId: '2b395754-da2f-4518-9fd8-facb575ef4e7',
  headerLogoId: '256f465a-7e6a-45f6-a9f9-60855f4dbd0c',
  footerLogoId: '68386af9-400c-4d92-93bb-c68c31189340',
  fontUrl: 'https://fonts.googleapis.com/css2?family=Quicksand&display=swap',
  defaultTitle: 'Flonga',
  titleTemplate: 'or-site-flonga | %s',
  noFollow: true,
  noIndex: true,
  cookieLawProId: '',
  extraHeaderURL: '',
  extraFooterURL: '',
  extraHeaderStyleURL: '',
  extraFooterStyleURL: '',
  customCss: ``,
  scripts: [
    {
      src: 'https://cdn.cookielaw.org/consent/a76f4ce9-2c47-44b5-9a56-89f604c43287/otSDKStub.js',
      strategy: 'afterInteractive',
      'data-domain-script': 'a76f4ce9-2c47-44b5-9a56-89f604c43287'
    },
    {
      id: 'ot-callback-script',
      strategy: 'afterInteractive',
      dangerouslySetInnerHTML: {
        __html: `
            function OptanonWrapper() {
            };
          `
      }
    },
    {
      id: 'idhb', // Required by HeaderLift.
      src: 'https://hb.improvedigital.com/pbw/headerlift.min.js',
      strategy: 'afterInteractive'
    },
    {
      src: 'https://pm.azerioncircle.com/p/focus/',
      strategy: 'afterInteractive',
    },
  ],
  links: [
  ],
};